
.logo4-wrapper img {

    margin-left: 0%;
    width: 100%; 
    margin-bottom: 0px;
    /* float: left; */
    margin-top: 5%;
    padding-right: 1%;
  }
  
