.card6 {
  position: relative;
  width: fit-content;
  margin: -.8rem;
  border-radius: 4px;
  border-color: rgb(197, 197, 197);
  padding: 0.3rem;
  overflow: hidden;
  color: #ec8a00;

}
      
