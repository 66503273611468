.homepage-item {
  margin: 1rem;
}

.homepage__content {
  padding: 1px;

}

.homepage__info {
  padding: 7rem;
  text-align: center;
  color:#ec8a00;
}

.homepage__image {
  padding-left: 0%;
  width: 100%;
  height: 12.5rem;
  margin-right: 1.5rem;
}
.homepage__image2 {
  padding-left: 0%;
  width: 100%;
  height: 12.5rem;
  margin-right: 1.5rem;
}
.homepage__image3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  text-align: center;
  margin-right: 1.5rem;

  padding-left: 20%;
  width: 90%;
  height: 12.5rem;
}
.homepage__image4 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  /* text-align: center; */
  margin-right: 1.5rem;

  padding-left: 10%;
  width: 90%;
  height: 12.5rem;
}
.homepage__image5 {
  display: grid;
  /* grid-template-columns: 1fr; */
  /* grid-template-rows: 1fr; */
  /* text-align: center; */
  /* margin-right: 1.5rem; */
  
  /* padding-left: 10%; */
  /* width: 90%; */
  /* height: 12.5%; */
}
.homepage__image6 {
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* grid-template-rows: 1fr 1fr 1fr; */
  text-align: center;
  /* margin-right: 1.5rem; */

  padding-left: 10%;
  width: 90%;
  height: 12.5rem;
}

.homepage__image img {
  width: 100%;
  height: 400%;
  object-fit: cover;
}
.homepage__image2 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.homepage__image3 img {
  width: 70%;
  height: 400%;
  object-fit: contain;
}
.homepage__image4 img {
  margin-top: 2%;
  width: 30%;
  height: 300%;
  object-fit: contain;
}
.homepage__image5 img {
  object-position: bottom;
  margin-top: 1%;
  /* object-position: 50%; */
  width: 156%;
  height: 330%;
  margin-bottom: 6.8%;
  object-fit: contain;
}
.homepage__image6 img {
  margin-top: 1%;
  width: 100%;
  height: 400%;
  object-fit: contain;
}


.homepage__info h2,
.homepage__info h5,
.homepage__info p {
  margin: 0 0 0.5rem 0;
}


.homepage__actions {
  padding: 1rem;
  text-align: center;
  /* border-top: 1px solid rgb(204, 204, 204); */
}

.homepage__modal-content {
  padding: 0;
}

.homepage__modal-actions {
  text-align: center;
}

.homepage__actions button,
.homepage__actions a {
  margin: 0.5rem;
}

.map-container {
  height: 15rem;
  width: 100%;
}

@media (min-width: 768px) {
  .homepage__image {
    height: 20rem;
  } 
}