.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #ec8a00;
  border-radius: 4px;
  background:#ec8a00;
  color: white;
  cursor: pointer;
  /* margin-right: 1rem; */
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #714000;
  border-color: #714000;
}

.button--inverse {
  background: transparent;
  color:#ec8a00;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #ec8a00;;
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #ec8a00;
  border-color: #ec8a00;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}