
.logo3-wrapper img {

    
    /* width: 400px; */
    /* margin-bottom: 0px; */
    padding-left: 30%;
    display: flex;
    align-items: center;
    width: 70%;
    height: 70%;
    

  }
  
.logo3-wrapper img {
    /* justify-content: center; */
    /* padding-right: 1% */
  
}