.card20 {
  position: relative;
  width: flex;
  margin: 0rem;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(88, 88, 88);
  padding: 0.5rem;
  overflow: hidden;
  /* background:   rgb(15, 15, 15); */
  color: #ec8a00;
  text-align: center;
 /* Replace 'your-image.jpg' with the URL or path to your image */
  
  /* background-image: url('../../../images/FootballAI25.png'); */
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  border-radius: 10px; /* Add some border radius for rounded corners */
  color: white; /* Set the text color to white or any other color that complements the background */
  /* text-align: center; Center-align the content inside the card */
  padding: 20px; /* Add padding to create space around the content */

}
.card-info20 {
  position: flex;
  margin-left: 0%;
  padding-right: 4%;
  padding-left: 4%;
  /* grid-template-columns: repeat(3, 1fr); */
  display: grid;
  /* background: #222; */
  width: 100%;
  /* max-width: 750px; */
  margin: 0 auto;
  
  /* grid-template-columns: repeat(auto-fill, [col-start] 220px [col-end]); */
  /* grid-template-columns: repeat( auto-fit, minmax(104px, 1fr) );   */
  grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );  
  grid-gap: 5px;
  
  /* border: 4px solid #e7e7e7; */
   
  border-radius: 9px;
   
   
}