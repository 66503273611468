.card11 {
  position: relative;
  width: 90%;
  margin: 5%;
  box-shadow: 0 2px 8px #ec8a00;
  /* border-radius: 9px; */
  padding: 0%;
  overflow: hidden;
  background:   rgb(35, 35, 35);
  /* color: #ec8a00; */
  color: rgb(255, 255, 255); 
  padding-right: 0rem;
  padding-left: 0rem; 
  
  border: 4px solid #ec8a00;
  background-image: url('../../../images/soccerAI2.jpeg'); /* Replace 'your-image.jpg' with the URL or path to your image */
  background-size: 900px 900px; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  border-radius: 15px; /* Add some border radius for rounded corners */
  /* text-align: center; Center-align the content inside the card */
  padding: 0px; /* Add padding to create space around the content */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Push elements apart to top and bottom */
  align-items: flex-start; /* Align items to the left */

}
      
.card-info11 {
  padding-right: 3.4rem;
  padding-left: 3rem;
   
   
}

.card11 p {
  margin-top: 4px; /* Adjust the margin to increase or decrease the space between paragraphs */
  margin-bottom: 4px; /* Adjust the margin to increase or decrease the space between paragraphs */
}
@media (max-width: 768px) {
  .card11 {
    position: relative;
    width: 90%;
    margin: 5%;
    box-shadow: 0 2px 8px #ec8a00;
    /* border-radius: 9px; */
    padding: 0%;
    overflow: hidden;
    background:   rgb(35, 35, 35);
    /* color: #ec8a00; */
    padding-right: 0rem;
    padding-left: 0rem; 
    
    border: 1px solid #ec8a00;
    /* Replace 'your-image.jpg' with the URL or path to your image */
    background-image: url('../../../images/soccerAI2.jpeg'); 
    background-size: 700% 160%;; /* This will make the image cover the entire card */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    border-radius: 9px; /* Add some border radius for rounded corners */
    /* color: rgb(255, 255, 255);  */
    /* text-align: center; Center-align the content inside the card */
    padding: 0px; /* Add padding to create space around the content */
    
  }
  

  .card11 p {
    margin-top: 4px; /* Adjust the margin to increase or decrease the space between paragraphs */
    margin-bottom: 4px; /* Adjust the margin to increase or decrease the space between paragraphs */
  }
  }
