.card5 {
  position: relative;
  width: fit-content;
  margin: .5rem;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(236, 236, 236);
  padding: 0rem;
  overflow: hidden;
  background:   rgb(54, 54, 54);
  color: #ec8a00;

}
      
.card-info {
  padding-right: 3.5rem;
  padding-left: 3rem;
  display: grid;
   
  grid-template-columns: repeat(3, 1fr);
   
   
}