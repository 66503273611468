.card18 {
  position: relative;
  margin: 0;
  box-shadow: 0 2px 5px #ec8a00;
  border-radius: 6px;
  padding: 0.5rem;
  overflow: hidden;
  background:   #ec8a00;
  display: grid;
  grid-column-gap: 50px;
  color: rgb(0, 0, 0);

}
