.card28 {
  position: relative;
  width: 95%;
  margin: 2.5%;
  margin-right: 0%;
  /* box-shadow: 0 2px 8px rgb(53, 53, 52); */
  border-radius: 6px;
  /* border-color: rgb(88, 88, 88); */
  /* padding: 0.5rem; */
  overflow: hidden;
  /* background:   rgb(15, 15, 15, .8); */
  /* color: #ec8a00; */
  text-align: center;
  background-image: linear-gradient(to bottom, #000000, rgb(15, 15, 15, .15));  
  /* Replace 'your-image.jpg' with the URL or path to your image */
  /* background-image: url('../../../images/FootballAI27.png');  */
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
 /* Add some border radius for rounded corners */
  /* border-radius: 15px;  */
  /* color: rgb(255, 255, 255);  */
  /* text-align: center; Center-align the content inside the card */
  /* padding: 70px; Add padding to create space around the content */
  
}
.card28-info {
  /* margin: 10%; */
  border: 4px solid rgb(88, 88, 88);
  
  border-radius: 15px;
   
   
}