.card30 {
  position: relative;
  width: 95%;
  margin: 2.5%;
  margin-right: 0%;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  /* border-color: rgb(88, 88, 88); */
  /* padding: 0.5rem; */
  overflow: hidden;
  background:   rgba(0, 0, 0, 0.8);
  border: 1px solid rgb(255, 255, 255);

  color: rgb(255, 255, 255); 
  /* color: #ec8a00; */
  text-align: center;
  /* Replace 'your-image.jpg' with the URL or path to your image */
  /* background-image: url('../../../images/FootballAI27.png');  */
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  border-radius: 4px; /* Add some border radius for rounded corners */
  /* text-align: center; Center-align the content inside the card */
  /* padding: 70px; Add padding to create space around the content */
  
}
.card30-info {
  /* margin: 10%; */
  border: 4px solid rgb(255, 255, 255);
  
  border-radius: 15px;
   
   
}