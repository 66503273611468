.card21 {
  position: relative;
  width: 100%; /* Make the card width 100% of its container */
  max-width: 2400px; /* Set a maximum width to control its size on larger screens */
  margin: 0 auto; 
  /* margin-left: auto;
  margin-right: auto; */
  /* Add some padding around the card content */
  /* padding: 2rem;  */
  box-shadow: 0 2px 18px #ec8a00;
  border-radius: 6px;
  overflow: hidden;
  background-image: linear-gradient(to bottom, rgba(18, 80, 1, 0.5), rgba(251, 125, 0, 0.5));
  
}

/* Media query for screens with a maximum width of 768px (adjust as needed) */
@media (max-width: 768px) {
  .card21 {
    max-width: 100%; /* Reduce the maximum width for smaller screens */
    margin-left: auto; /* Center the card on smaller screens */
    margin-right: auto;
    /* padding: 1rem;  */
  }
}
