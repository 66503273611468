.card29 {
  position: relative;
  width: 100%; /* Set the card width to 100% to make it adapt to the screen size */
  margin: 0 auto; /* Center the card horizontally */
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(88, 88, 88);
  padding: 0.5rem;
  overflow: hidden;
  /* background: rgb(15, 15, 15); */
  /* color: #ec8a00; */
  text-align: center;
  background-image: linear-gradient(to bottom, rgba(7, 66, 22, 0.5), rgba(249, 180, 76, 0.4));
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  /* color: white; */
  padding: 20px;

  padding: 0.5rem;
  font-size: 16px; /* Default font size for larger screens */
}

.card-info29 {
  position: flex;
  margin-left: 0%;
  /* padding-right: 4%; */
  /* padding-left: 4%; */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Display three items in a row for larger screens */
  /* Adjust column width as needed */
  /* grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));  */
  grid-gap: 1px; /* Adjust the gap between cards as needed */
  border-radius: 9px;
  font-size: 16px; /* Default font size for larger screens */
  
}
.card29 h3 {
  /* font-size: 9px;  */
  margin-top: 4px; /* Adjust the margin to increase or decrease the space between paragraphs */
  margin-bottom: 4px;
  color: #ffd79f;

}
.card29 h6 {
  margin-top: 4px; /* Adjust the margin to increase or decrease the space between paragraphs */
  margin-bottom: 6px;
  /* font-size: 9px;  */
  color: #ffd79f;

}


/* Media query for screens with a maximum width of 768px (adjust as needed) */
@media (max-width: 768px) {
  .card-info29 {
    /* Display three items in a row for larger screens */    
    grid-template-columns: repeat(3, 1fr); 
    /* Cards take full width on smaller screens */
    /* grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));  */
    grid-gap: 0px; /* Reduce the gap between cards on smaller screens */
    font-size: 11px; /* Adjust font size for smaller screens */
  }
  .card29 h3 {
    margin-top: 4px; 

    font-size: 12px; /* Adjust font size for smaller screens */
    color: #ffd79f;

  }
  .card29 h6 {
    margin-top: 4px;
    margin-bottom: 6px;  
    font-size: 10px; /* Adjust font size for smaller screens */
    color: #ffd79f;
  
  }
  }
