.card22 {
  position: relative;
  width: 97%;
  margin: 1.5%;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(56, 56, 56);
  padding: .25rem;
  overflow: hidden;
  background: rgb(54, 54, 54);
  color: #ec8a00;
  align-content: center;

  /* width: 300px;
  height: 200px; */
  background-image: url('../../../images/soccerAI3.png'); /* Replace 'your-image.jpg' with the URL or path to your image */
  
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: repeat; /* Prevent the background image from repeating */
  border-radius: 10px; /* Add some border radius for rounded corners */
  color: white; /* Set the text color to white or any other color that complements the background */
  /* text-align: center; Center-align the content inside the card */
  padding: 0px; /* Add padding to create space around the content */



}
      
.card-info22 {
  border: 4px solid #e7e7e7;
  width: 90%;
  border-radius: 4px;
   
   
}