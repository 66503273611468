.card23 {
  position: relative;
  width: 95%;
  margin: 2.5%;
  margin-right: 0%;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(0, 0, 0);
  /* padding: 0.5rem; */
  overflow: hidden;
  /* background:   rgb(15, 15, 15); */
  color: #ec8a00;
  text-align: center;
  /* Replace 'your-image.jpg' with the URL or path to your image */
  background-image: url('../../../images/soccerAI3.png'); 
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  border-radius: 15px; /* Add some border radius for rounded corners */
  /* color: rgb(255, 255, 255);  */
  /* text-align: center; Center-align the content inside the card */
  padding: 25px; /* Add padding to create space around the content */
  
}
.card23-info {
  /* margin: 10%; */
  border: 4px solid rgb(88, 88, 88);
  
  border-radius: 15px;
   
   
}