
.logo-wrapper img {

    
    width: 120px;
    margin-bottom: -5px;
    float: left;

  }
  
.logo-wrapper img {
    justify-content: left;
    padding-right: 3%
  
}