
.logo2-wrapper img {

    
    width: 100px;
    margin-bottom: 0px;
    float: left;

  }
  
.logo2-wrapper img {
    justify-content: left;
    padding-right: 1%
  
}