.sponsor-item {
  margin: 1rem 0;
}

.sponsor-item__content {
  padding: 10px;

}

.sponsor-item__info {
  padding: 7rem;
  text-align: center;
  color: rgb(248, 182, 0);
}

.sponsor-item__image {
  padding-left: 20%;
  width: 80%;
  height: 12.5rem;
  margin-right: 1.5rem;
}

.sponsor-item__image img {
  width: 100%;
  height: 130%;
  object-fit: cover;
}

.sponsor-item__info h2,
.sponsor-item__info h3,
.sponsor-item__info p {
  margin: 0 0 0.5rem 0;
}


.sponsor-item__actions {
  padding: 1rem;
  text-align: center;
  /* border-top: 1px solid rgb(204, 204, 204); */
}

.sponsor-item__modal-content {
  padding: 0;
}

.sponsor-item__modal-actions {
  text-align: center;
}

.sponsor-item__actions button,
.sponsor-item__actions a {
  margin: 0.5rem;
}

.map-container {
  height: 15rem;
  width: 100%;
}

@media (min-width: 768px) {
  .sponsor-item__image {
    height: 20rem;
  } 
}