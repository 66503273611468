.button2 {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #129e05;
  border-radius: 4px;
  background:#129e05;
  color: white;
  cursor: pointer;
  /* margin-right: 1rem; */
  text-decoration: none;
  display: inline-block;
}

.button2:focus {
  outline: none;
}

.button2:hover,
.button2:active {
  background: #065e01;
  border-color: #065e01;
}

.button2--inverse {
  background: transparent;
  color:#129e05;
}

.button2--inverse:hover,
.button22--inverse:active {
  color: white;
  background: #129e05;;
}

.button2--danger {
  background: #830000;
  border-color: #830000;
}

.button2--danger:hover,
.button2--danger:active {
  background: #129e05;
  border-color: #129e05;
}

.button2:disabled,
.button2:hover:disabled,
.button2:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button2--small {
  font-size: 0.8rem;
}

.button2--big {
  font-size: 1.5rem;
}