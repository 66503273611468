@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  /* Uncomment this line to use a relative path */
  /* background-image: url('images/football.svg'); */
  /* background-image: url('images/logo.png'); */
  /* background-image: url('images/footballField.jpeg'); */
  background-image: url('images/soccerAI1.jpeg');
  /* background-image: url('images/footballField.svg'); */
  /* Or use an absolute URL if the image is hosted elsewhere */
  /* background-image: linear-gradient(to bottom, #19432b, #ff7722);   */
  /* background-image: url('https://example.com/path-to-your-image.jpg'); */
  
  /* Specify background properties like size, repeat, and position if needed */
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  background-attachment: fixed;
  border-radius: 10px; /* Add some border radius for rounded corners */
  /* text-align: center; Center-align the content inside the card */
  /* padding: 20px; */
  background-color: #2c2b2b;
    /* margin: 0; */
  /* background: #053002; */

}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}