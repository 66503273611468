.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 70%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

  background-image: url('../../../images/FootballAI28.png'); /* Replace 'your-image.jpg' with the URL or path to your image */
  
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: repeat; /* Prevent the background image from repeating */
  border-radius: 10px; /* Add some border radius for rounded corners */
  /* color: white;  */
  /* text-align: center; Center-align the content inside the card */
  padding: 20px; /* Add padding to create space around the content */

}
