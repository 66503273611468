.card12 {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  box-shadow: 0 2px 18px #ec8a00;
  border-radius: 6px;
  padding: 0.5rem;
  overflow: hidden;
  background: rgb(15, 15, 15);
}

