.card24 {
  position: relative;
  width: flex;
  margin: 0rem;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: #ec8a00;
  padding: 0.5rem;
  overflow: hidden;
  background:   rgb(15, 15, 15);
  color: #ec8a00;
  text-align: center;
}
.card-info24 {
  position: flex;
  margin-left: 0%;
  padding-right: 0%;
  padding-left: 2%;
  /* grid-template-columns: repeat(3, 1fr); */
  display: grid;
  /* background: #222; */
  width: 100%;
  /* max-width: 750px; */
  margin: 0 auto;
  
  /* grid-template-columns: repeat(auto-fill, 24em); */
  grid-template-columns: repeat( auto-fit, minmax(22em, 1fr) );  
  grid-gap: 5px;
  
  border: 4px solid #e7e7e7;
   
  border-radius: 9px;
   
   
}