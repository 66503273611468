@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #510077;
  border-color: #510077 transparent #510077 transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.logo3-wrapper img {

    
    /* width: 400px; */
    /* margin-bottom: 0px; */
    padding-left: 30%;
    display: flex;
    align-items: center;
    width: 70%;
    height: 70%;
    

  }
  
.logo3-wrapper img {
    /* justify-content: center; */
    /* padding-right: 1% */
  
}
.card4 {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: .5rem;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(88, 88, 88);
  padding: 0.5rem;
  overflow: hidden;
  background:   rgb(15, 15, 15);
  color: #ec8a00;
  text-align: center;
}
.card-info4 {
  border: 4px solid #e7e7e7;
   
  border-radius: 4px;
   
   
}
.card11 {
  position: relative;
  width: 90%;
  margin: 5%;
  box-shadow: 0 2px 8px #ec8a00;
  /* border-radius: 9px; */
  padding: 0%;
  overflow: hidden;
  background:   rgb(35, 35, 35);
  /* color: #ec8a00; */
  color: rgb(255, 255, 255); 
  padding-right: 0rem;
  padding-left: 0rem; 
  
  border: 4px solid #ec8a00;
  background-image: url(/static/media/soccerAI2.5cded8f3.jpeg); /* Replace 'your-image.jpg' with the URL or path to your image */
  background-size: 900px 900px; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  border-radius: 15px; /* Add some border radius for rounded corners */
  /* text-align: center; Center-align the content inside the card */
  padding: 0px; /* Add padding to create space around the content */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Push elements apart to top and bottom */
  align-items: flex-start; /* Align items to the left */

}
      
.card-info11 {
  padding-right: 3.4rem;
  padding-left: 3rem;
   
   
}

.card11 p {
  margin-top: 4px; /* Adjust the margin to increase or decrease the space between paragraphs */
  margin-bottom: 4px; /* Adjust the margin to increase or decrease the space between paragraphs */
}
@media (max-width: 768px) {
  .card11 {
    position: relative;
    width: 90%;
    margin: 5%;
    box-shadow: 0 2px 8px #ec8a00;
    /* border-radius: 9px; */
    padding: 0%;
    overflow: hidden;
    background:   rgb(35, 35, 35);
    /* color: #ec8a00; */
    padding-right: 0rem;
    padding-left: 0rem; 
    
    border: 1px solid #ec8a00;
    /* Replace 'your-image.jpg' with the URL or path to your image */
    background-image: url(/static/media/soccerAI2.5cded8f3.jpeg); 
    background-size: 700% 160%;; /* This will make the image cover the entire card */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    border-radius: 9px; /* Add some border radius for rounded corners */
    /* color: rgb(255, 255, 255);  */
    /* text-align: center; Center-align the content inside the card */
    padding: 0px; /* Add padding to create space around the content */
    
  }
  

  .card11 p {
    margin-top: 4px; /* Adjust the margin to increase or decrease the space between paragraphs */
    margin-bottom: 4px; /* Adjust the margin to increase or decrease the space between paragraphs */
  }
  }

.card12 {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  box-shadow: 0 2px 18px #ec8a00;
  border-radius: 6px;
  padding: 0.5rem;
  overflow: hidden;
  background: rgb(15, 15, 15);
}


.card15 {
  position: relative;
  margin: 0;
  box-shadow: 0 2px 5px #ec8a00;
  border-radius: 6px;
  padding: 0.5rem;
  overflow: hidden;
  background:   #ec8a00;
  display: grid;
  grid-column-gap: 50px;
  color: rgb(0, 0, 0);

}

.card16 {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5%;
  margin-right: 47%;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(88, 88, 88);
  padding: 0.5rem;
  overflow: hidden;
  background:   rgb(15, 15, 15);
  color: #ec8a00;
  text-align: center;
}
.card-info16 {
  /* margin: 10%; */
  border: 4px solid #e7e7e7;
   
  border-radius: 15px;
   
   
}
.card18 {
  position: relative;
  margin: 0;
  box-shadow: 0 2px 5px #ec8a00;
  border-radius: 6px;
  padding: 0.5rem;
  overflow: hidden;
  background:   #ec8a00;
  display: grid;
  grid-column-gap: 50px;
  color: rgb(0, 0, 0);

}

.card19 {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 10%;
  margin-right: 10%;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(88, 88, 88);
  padding: 0.5rem;
  overflow: hidden;
  background:   rgb(15, 15, 15);
  color: #ec8a00;
  text-align: center;
}
.card-info19 {
  /* margin: 10%; */
  border: 4px solid rgb(88, 88, 88);
   
  border-radius: 15px;
   
   
}
.card17 {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5%;
  margin-right: 5%;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(88, 88, 88);
  padding: 0.5rem;
  overflow: hidden;
  background:   rgba(15, 15, 15, 0.74);
  color: #ec8a00;
  text-align: center;
}
.card-info17 {
  /* margin: 10%; */
  border: 4px solid rgb(88, 88, 88);
   
  border-radius: 15px;
   
   
}
.card27 {
  position: relative;
  width: 95%;
  margin: 2.5%;
  margin-right: 0%;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(88, 88, 88);
  /* padding: 0.5rem; */
  overflow: hidden;
  background:   rgb(15, 15, 15, .8);
  color: rgb(255, 255, 255); 
  /* color: #ec8a00; */
  text-align: center;
  /* Replace 'your-image.jpg' with the URL or path to your image */
  /* background-image: url('../../../images/FootballAI27.png');  */
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  border-radius: 10px; /* Add some border radius for rounded corners */
  /* text-align: center; Center-align the content inside the card */
  /* padding: 70px; Add padding to create space around the content */
  
}
.card27-info {
  /* margin: 10%; */
  border: 4px solid rgb(88, 88, 88);
  
  border-radius: 15px;
   
   
}
.card28 {
  position: relative;
  width: 95%;
  margin: 2.5%;
  margin-right: 0%;
  /* box-shadow: 0 2px 8px rgb(53, 53, 52); */
  border-radius: 6px;
  /* border-color: rgb(88, 88, 88); */
  /* padding: 0.5rem; */
  overflow: hidden;
  /* background:   rgb(15, 15, 15, .8); */
  /* color: #ec8a00; */
  text-align: center;
  background-image: linear-gradient(to bottom, #000000, rgb(15, 15, 15, .15));  
  /* Replace 'your-image.jpg' with the URL or path to your image */
  /* background-image: url('../../../images/FootballAI27.png');  */
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
 /* Add some border radius for rounded corners */
  /* border-radius: 15px;  */
  /* color: rgb(255, 255, 255);  */
  /* text-align: center; Center-align the content inside the card */
  /* padding: 70px; Add padding to create space around the content */
  
}
.card28-info {
  /* margin: 10%; */
  border: 4px solid rgb(88, 88, 88);
  
  border-radius: 15px;
   
   
}
.card29 {
  position: relative;
  width: 100%; /* Set the card width to 100% to make it adapt to the screen size */
  margin: 0 auto; /* Center the card horizontally */
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(88, 88, 88);
  padding: 0.5rem;
  overflow: hidden;
  /* background: rgb(15, 15, 15); */
  /* color: #ec8a00; */
  text-align: center;
  background-image: linear-gradient(to bottom, rgba(7, 66, 22, 0.5), rgba(249, 180, 76, 0.4));
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  /* color: white; */
  padding: 20px;

  padding: 0.5rem;
  font-size: 16px; /* Default font size for larger screens */
}

.card-info29 {
  position: flex;
  margin-left: 0%;
  /* padding-right: 4%; */
  /* padding-left: 4%; */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Display three items in a row for larger screens */
  /* Adjust column width as needed */
  /* grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));  */
  grid-gap: 1px; /* Adjust the gap between cards as needed */
  border-radius: 9px;
  font-size: 16px; /* Default font size for larger screens */
  
}
.card29 h3 {
  /* font-size: 9px;  */
  margin-top: 4px; /* Adjust the margin to increase or decrease the space between paragraphs */
  margin-bottom: 4px;
  color: #ffd79f;

}
.card29 h6 {
  margin-top: 4px; /* Adjust the margin to increase or decrease the space between paragraphs */
  margin-bottom: 6px;
  /* font-size: 9px;  */
  color: #ffd79f;

}


/* Media query for screens with a maximum width of 768px (adjust as needed) */
@media (max-width: 768px) {
  .card-info29 {
    /* Display three items in a row for larger screens */    
    grid-template-columns: repeat(3, 1fr); 
    /* Cards take full width on smaller screens */
    /* grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));  */
    grid-gap: 0px; /* Reduce the gap between cards on smaller screens */
    font-size: 11px; /* Adjust font size for smaller screens */
  }
  .card29 h3 {
    margin-top: 4px; 

    font-size: 12px; /* Adjust font size for smaller screens */
    color: #ffd79f;

  }
  .card29 h6 {
    margin-top: 4px;
    margin-bottom: 6px;  
    font-size: 10px; /* Adjust font size for smaller screens */
    color: #ffd79f;
  
  }
  }

.homepage-item {
  margin: 1rem;
}

.homepage__content {
  padding: 1px;

}

.homepage__info {
  padding: 7rem;
  text-align: center;
  color:#ec8a00;
}

.homepage__image {
  padding-left: 0%;
  width: 100%;
  height: 12.5rem;
  margin-right: 1.5rem;
}
.homepage__image2 {
  padding-left: 0%;
  width: 100%;
  height: 12.5rem;
  margin-right: 1.5rem;
}
.homepage__image3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  text-align: center;
  margin-right: 1.5rem;

  padding-left: 20%;
  width: 90%;
  height: 12.5rem;
}
.homepage__image4 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  /* text-align: center; */
  margin-right: 1.5rem;

  padding-left: 10%;
  width: 90%;
  height: 12.5rem;
}
.homepage__image5 {
  display: grid;
  /* grid-template-columns: 1fr; */
  /* grid-template-rows: 1fr; */
  /* text-align: center; */
  /* margin-right: 1.5rem; */
  
  /* padding-left: 10%; */
  /* width: 90%; */
  /* height: 12.5%; */
}
.homepage__image6 {
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* grid-template-rows: 1fr 1fr 1fr; */
  text-align: center;
  /* margin-right: 1.5rem; */

  padding-left: 10%;
  width: 90%;
  height: 12.5rem;
}

.homepage__image img {
  width: 100%;
  height: 400%;
  object-fit: cover;
}
.homepage__image2 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.homepage__image3 img {
  width: 70%;
  height: 400%;
  object-fit: contain;
}
.homepage__image4 img {
  margin-top: 2%;
  width: 30%;
  height: 300%;
  object-fit: contain;
}
.homepage__image5 img {
  object-position: bottom;
  margin-top: 1%;
  /* object-position: 50%; */
  width: 156%;
  height: 330%;
  margin-bottom: 6.8%;
  object-fit: contain;
}
.homepage__image6 img {
  margin-top: 1%;
  width: 100%;
  height: 400%;
  object-fit: contain;
}


.homepage__info h2,
.homepage__info h5,
.homepage__info p {
  margin: 0 0 0.5rem 0;
}


.homepage__actions {
  padding: 1rem;
  text-align: center;
  /* border-top: 1px solid rgb(204, 204, 204); */
}

.homepage__modal-content {
  padding: 0;
}

.homepage__modal-actions {
  text-align: center;
}

.homepage__actions button,
.homepage__actions a {
  margin: 0.5rem;
}

.map-container {
  height: 15rem;
  width: 100%;
}

@media (min-width: 768px) {
  .homepage__image {
    height: 20rem;
  } 
}
.main-header {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}

main {
  margin-top: 5rem;
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}
.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
}

.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  background: #3a3a3a;
  border-color: #292929;
  color: #ffffff;
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #292929;
  color: white;
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.2rem;
  }

  .nav-links a {
    color: #ec8a00;
    text-decoration: none;
  }

  .nav-links button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    background: #363636;
    color: #ec8a00;
  }
}

.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 70%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

  background-image: url(/static/media/FootballAI28.71442315.png); /* Replace 'your-image.jpg' with the URL or path to your image */
  
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: repeat; /* Prevent the background image from repeating */
  border-radius: 10px; /* Add some border radius for rounded corners */
  /* color: white;  */
  /* text-align: center; Center-align the content inside the card */
  padding: 20px; /* Add padding to create space around the content */

}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: #ec8a00;
}

.main-navigation__title a {
  text-decoration: none;
  color: #ec8a00;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}

.logo-wrapper img {

    
    width: 120px;
    margin-bottom: -5px;
    float: left;

  }
  
.logo-wrapper img {
    justify-content: left;
    padding-right: 3%
  
}

.logo4-wrapper img {

    margin-left: 0%;
    width: 100%; 
    margin-bottom: 0px;
    /* float: left; */
    margin-top: 5%;
    padding-right: 1%;
  }
  

.card21 {
  position: relative;
  width: 100%; /* Make the card width 100% of its container */
  max-width: 2400px; /* Set a maximum width to control its size on larger screens */
  margin: 0 auto; 
  /* margin-left: auto;
  margin-right: auto; */
  /* Add some padding around the card content */
  /* padding: 2rem;  */
  box-shadow: 0 2px 18px #ec8a00;
  border-radius: 6px;
  overflow: hidden;
  background-image: linear-gradient(to bottom, rgba(18, 80, 1, 0.5), rgba(251, 125, 0, 0.5));
  
}

/* Media query for screens with a maximum width of 768px (adjust as needed) */
@media (max-width: 768px) {
  .card21 {
    max-width: 100%; /* Reduce the maximum width for smaller screens */
    margin-left: auto; /* Center the card on smaller screens */
    margin-right: auto;
    /* padding: 1rem;  */
  }
}

.card22 {
  position: relative;
  width: 97%;
  margin: 1.5%;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(56, 56, 56);
  padding: .25rem;
  overflow: hidden;
  background: rgb(54, 54, 54);
  color: #ec8a00;
  align-content: center;

  /* width: 300px;
  height: 200px; */
  background-image: url(/static/media/soccerAI3.594a6ac7.png); /* Replace 'your-image.jpg' with the URL or path to your image */
  
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: repeat; /* Prevent the background image from repeating */
  border-radius: 10px; /* Add some border radius for rounded corners */
  color: white; /* Set the text color to white or any other color that complements the background */
  /* text-align: center; Center-align the content inside the card */
  padding: 0px; /* Add padding to create space around the content */



}
      
.card-info22 {
  border: 4px solid #e7e7e7;
  width: 90%;
  border-radius: 4px;
   
   
}
.card23 {
  position: relative;
  width: 95%;
  margin: 2.5%;
  margin-right: 0%;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(0, 0, 0);
  /* padding: 0.5rem; */
  overflow: hidden;
  /* background:   rgb(15, 15, 15); */
  color: #ec8a00;
  text-align: center;
  /* Replace 'your-image.jpg' with the URL or path to your image */
  background-image: url(/static/media/soccerAI3.594a6ac7.png); 
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  border-radius: 15px; /* Add some border radius for rounded corners */
  /* color: rgb(255, 255, 255);  */
  /* text-align: center; Center-align the content inside the card */
  padding: 25px; /* Add padding to create space around the content */
  
}
.card23-info {
  /* margin: 10%; */
  border: 4px solid rgb(88, 88, 88);
  
  border-radius: 15px;
   
   
}
.card26 {
  position: relative;
  width: 95%;
  margin: 2.5%;
  margin-right: 0%;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(88, 88, 88);
  /* padding: 0.5rem; */
  overflow: hidden;
  /* background:   rgb(15, 15, 15); */
  color: #ec8a00;
  text-align: center;
  /* Replace 'your-image.jpg' with the URL or path to your image */
  /* background-image: url('../../../images/FootballAI27.png');  */
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  border-radius: 15px; /* Add some border radius for rounded corners */
  color: rgb(255, 255, 255); 
  /* text-align: center; Center-align the content inside the card */
  padding: 20px; /* Add padding to create space around the content */
  
}
.card26-info {
  /* margin: 10%; */
  border: 4px solid rgb(88, 88, 88);
  
  border-radius: 15px;
   
   
}
.card30 {
  position: relative;
  width: 95%;
  margin: 2.5%;
  margin-right: 0%;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  /* border-color: rgb(88, 88, 88); */
  /* padding: 0.5rem; */
  overflow: hidden;
  background:   rgba(0, 0, 0, 0.8);
  border: 1px solid rgb(255, 255, 255);

  color: rgb(255, 255, 255); 
  /* color: #ec8a00; */
  text-align: center;
  /* Replace 'your-image.jpg' with the URL or path to your image */
  /* background-image: url('../../../images/FootballAI27.png');  */
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  border-radius: 4px; /* Add some border radius for rounded corners */
  /* text-align: center; Center-align the content inside the card */
  /* padding: 70px; Add padding to create space around the content */
  
}
.card30-info {
  /* margin: 10%; */
  border: 4px solid rgb(255, 255, 255);
  
  border-radius: 15px;
   
   
}
.card24 {
  position: relative;
  width: flex;
  margin: 0rem;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: #ec8a00;
  padding: 0.5rem;
  overflow: hidden;
  background:   rgb(15, 15, 15);
  color: #ec8a00;
  text-align: center;
}
.card-info24 {
  position: flex;
  margin-left: 0%;
  padding-right: 0%;
  padding-left: 2%;
  /* grid-template-columns: repeat(3, 1fr); */
  display: grid;
  /* background: #222; */
  width: 100%;
  /* max-width: 750px; */
  margin: 0 auto;
  
  /* grid-template-columns: repeat(auto-fill, 24em); */
  grid-template-columns: repeat( auto-fit, minmax(22em, 1fr) );  
  grid-gap: 5px;
  
  border: 4px solid #e7e7e7;
   
  border-radius: 9px;
   
   
}

.logo2-wrapper img {

    
    width: 100px;
    margin-bottom: 0px;
    float: left;

  }
  
.logo2-wrapper img {
    justify-content: left;
    padding-right: 1%
  
}
.card20 {
  position: relative;
  width: flex;
  margin: 0rem;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(88, 88, 88);
  padding: 0.5rem;
  overflow: hidden;
  /* background:   rgb(15, 15, 15); */
  color: #ec8a00;
  text-align: center;
 /* Replace 'your-image.jpg' with the URL or path to your image */
  
  /* background-image: url('../../../images/FootballAI25.png'); */
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  border-radius: 10px; /* Add some border radius for rounded corners */
  color: white; /* Set the text color to white or any other color that complements the background */
  /* text-align: center; Center-align the content inside the card */
  padding: 20px; /* Add padding to create space around the content */

}
.card-info20 {
  position: flex;
  margin-left: 0%;
  padding-right: 4%;
  padding-left: 4%;
  /* grid-template-columns: repeat(3, 1fr); */
  display: grid;
  /* background: #222; */
  width: 100%;
  /* max-width: 750px; */
  margin: 0 auto;
  
  /* grid-template-columns: repeat(auto-fill, [col-start] 220px [col-end]); */
  /* grid-template-columns: repeat( auto-fit, minmax(104px, 1fr) );   */
  grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );  
  grid-gap: 5px;
  
  /* border: 4px solid #e7e7e7; */
   
  border-radius: 9px;
   
   
}
.card5 {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: .5rem;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(236, 236, 236);
  padding: 0rem;
  overflow: hidden;
  background:   rgb(54, 54, 54);
  color: #ec8a00;

}
      
.card-info {
  padding-right: 3.5rem;
  padding-left: 3rem;
  display: grid;
   
  grid-template-columns: repeat(3, 1fr);
   
   
}
.card6 {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: -.8rem;
  border-radius: 4px;
  border-color: rgb(197, 197, 197);
  padding: 0.3rem;
  overflow: hidden;
  color: #ec8a00;

}
      

.card7 {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: .5rem;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(56, 56, 56);
  padding: .25rem;
  overflow: hidden;
  background:   rgb(54, 54, 54);
  color: #ec8a00;
  align-content: center;

}
      
.card-info7 {
  border: 4px solid #e7e7e7;
   
  border-radius: 4px;
   
   
}
.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #ec8a00;
  border-radius: 4px;
  background:#ec8a00;
  color: white;
  cursor: pointer;
  /* margin-right: 1rem; */
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #714000;
  border-color: #714000;
}

.button--inverse {
  background: transparent;
  color:#ec8a00;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #ec8a00;;
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #ec8a00;
  border-color: #ec8a00;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}
.button2 {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #129e05;
  border-radius: 4px;
  background:#129e05;
  color: white;
  cursor: pointer;
  /* margin-right: 1rem; */
  text-decoration: none;
  display: inline-block;
}

.button2:focus {
  outline: none;
}

.button2:hover,
.button2:active {
  background: #065e01;
  border-color: #065e01;
}

.button2--inverse {
  background: transparent;
  color:#129e05;
}

.button2--inverse:hover,
.button22--inverse:active {
  color: white;
  background: #129e05;;
}

.button2--danger {
  background: #830000;
  border-color: #830000;
}

.button2--danger:hover,
.button2--danger:active {
  background: #129e05;
  border-color: #129e05;
}

.button2:disabled,
.button2:hover:disabled,
.button2:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button2--small {
  font-size: 0.8rem;
}

.button2--big {
  font-size: 1.5rem;
}
.sponsor-item {
  margin: 1rem 0;
}

.sponsor-item__content {
  padding: 10px;

}

.sponsor-item__info {
  padding: 7rem;
  text-align: center;
  color: rgb(248, 182, 0);
}

.sponsor-item__image {
  padding-left: 20%;
  width: 80%;
  height: 12.5rem;
  margin-right: 1.5rem;
}

.sponsor-item__image img {
  width: 100%;
  height: 130%;
  object-fit: cover;
}

.sponsor-item__info h2,
.sponsor-item__info h3,
.sponsor-item__info p {
  margin: 0 0 0.5rem 0;
}


.sponsor-item__actions {
  padding: 1rem;
  text-align: center;
  /* border-top: 1px solid rgb(204, 204, 204); */
}

.sponsor-item__modal-content {
  padding: 0;
}

.sponsor-item__modal-actions {
  text-align: center;
}

.sponsor-item__actions button,
.sponsor-item__actions a {
  margin: 0.5rem;
}

.map-container {
  height: 15rem;
  width: 100%;
}

@media (min-width: 768px) {
  .sponsor-item__image {
    height: 20rem;
  } 
}
.profile-item {
  margin: 1rem 0;
}

.profile-item__content {
  padding: 15px;

}

.profile-item__info {
  padding: 7rem;
  text-align: center;
  color: #ec8a00;
}

.profile-item__image {
  padding-left: 20%;
  width: 80%;
  height: 12.5rem;
  margin-right: 1.5rem;
}

.profile-item__image img {
  width: 100%;
  height: 130%;
  object-fit: cover;
}

.profile-item__info h2,
.profile-item__info h3,
.profile-item__info p {
  margin: 0 0 0.5rem 0;
}


.profile-item__actions {
  padding: 1rem;
  text-align: center;
  /* border-top: 1px solid rgb(204, 204, 204); */
}

.profile-item__modal-content {
  padding: 0;
}

.profile-item__modal-actions {
  text-align: center;
}

.profile-item__actions button,
.profile-item__actions a {
  margin: 0.5rem;
}

.map-container {
  height: 15rem;
  width: 100%;
}

@media (min-width: 768px) {
  .profile-item__image {
    height: 20rem;
  } 
}
* {
  box-sizing: border-box;
}

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  /* Uncomment this line to use a relative path */
  /* background-image: url('images/football.svg'); */
  /* background-image: url('images/logo.png'); */
  /* background-image: url('images/footballField.jpeg'); */
  background-image: url(/static/media/soccerAI1.2817bdc0.jpeg);
  /* background-image: url('images/footballField.svg'); */
  /* Or use an absolute URL if the image is hosted elsewhere */
  /* background-image: linear-gradient(to bottom, #19432b, #ff7722);   */
  /* background-image: url('https://example.com/path-to-your-image.jpg'); */
  
  /* Specify background properties like size, repeat, and position if needed */
  background-size: cover; /* This will make the image cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  background-attachment: fixed;
  border-radius: 10px; /* Add some border radius for rounded corners */
  /* text-align: center; Center-align the content inside the card */
  /* padding: 20px; */
  background-color: #2c2b2b;
    /* margin: 0; */
  /* background: #053002; */

}

.slide-in-left-enter {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.slide-in-left-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
