.card19 {
  position: relative;
  width: fit-content;
  margin: 10%;
  margin-right: 10%;
  box-shadow: 0 2px 8px rgb(53, 53, 52);
  border-radius: 6px;
  border-color: rgb(88, 88, 88);
  padding: 0.5rem;
  overflow: hidden;
  background:   rgb(15, 15, 15);
  color: #ec8a00;
  text-align: center;
}
.card-info19 {
  /* margin: 10%; */
  border: 4px solid rgb(88, 88, 88);
   
  border-radius: 15px;
   
   
}